import { PDTypes } from '~/api/schema/orderSimulation';

export enum EyeTestStatuses {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum PrescriptionStatuses {
  INACTIVE = 'inactive',
  ACTIVE = 'active'
}

export enum EyeTestCompletionStates {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete'
}

export enum EyeTestWarningFlags {
  INCOMPLETE = 'incomplete',
  ARCHIVED = 'archived',
  EXPIRED = 'expired',
  NOT_DEFAULT = 'not_default'
}

export enum EyePositions {
  RIGHT = 'right',
  LEFT = 'left'
}

export enum EyeTestExpiryStatus {
  ACTIVE = 'Active',
  EXPIRING_SOON = 'Expiring soon',
  EXPIRED = 'Expired'
}

export enum EyeTestCreatedEvents {
  CREATING_COMPLETE_PRESCRIPTION = 'creating_complete_prescription',
  CREATING_INCOMPLETE_PRESCRIPTION = 'creating_incomplete_prescription',
  COMPLETING_PRESCRIPTION = 'completing_prescription'
}

export enum VisionType {
  DISTANCE = 'SVD',
  INTERMEDIATE = 'SVI',
  NEAR = 'SVN',
  READING = 'RR',
  DIGITAL = 'Digital',
  PROGRESSIVE = 'Progressive',
  OCCUPATION = 'Occupation',
  OCCUPATIONAL = 'Occupational',
  BI_FOCAL = 'Bi-focal',
  BI__FOCAL = 'Bi focal'
}

export const multifocalVisionTypes = [
  VisionType.DIGITAL,
  VisionType.PROGRESSIVE,
  VisionType.OCCUPATION,
  VisionType.BI_FOCAL
];

export const singleVisionTypes = [VisionType.DISTANCE, VisionType.NEAR, VisionType.INTERMEDIATE];

export const UISingleVisionTypes = [VisionType.NEAR, VisionType.INTERMEDIATE, VisionType.DISTANCE];
export const UIMultifocalVisionTypes = [
  VisionType.DIGITAL,
  VisionType.OCCUPATIONAL,
  VisionType.BI_FOCAL,
  VisionType.PROGRESSIVE
];

export const visionTypeUITitles = {
  [VisionType.NEAR]: 'Near vision',
  [VisionType.INTERMEDIATE]: 'Intermediate vision',
  [VisionType.DISTANCE]: 'Distance vision',
  [VisionType.DIGITAL]: 'Digital',
  [VisionType.OCCUPATION]: 'Occupational',
  [VisionType.OCCUPATIONAL]: 'Occupational',
  [VisionType.BI_FOCAL]: 'Bi-focal',
  [VisionType.PROGRESSIVE]: 'Progressive'
};

export enum PDFieldPartNames {
  DISTANCE = 'distance',
  INTERMEDIATE = 'inter',
  NEAR = 'near'
}

export const visionTypeFieldPartNamesMap: { [key in VisionType]: PDFieldPartNames } = {
  [VisionType.DISTANCE]: PDFieldPartNames.DISTANCE,
  [VisionType.INTERMEDIATE]: PDFieldPartNames.INTERMEDIATE,
  [VisionType.NEAR]: PDFieldPartNames.NEAR,
  [VisionType.READING]: PDFieldPartNames.NEAR,
  [VisionType.DIGITAL]: PDFieldPartNames.DISTANCE,
  [VisionType.PROGRESSIVE]: PDFieldPartNames.DISTANCE,
  [VisionType.OCCUPATION]: PDFieldPartNames.INTERMEDIATE,
  [VisionType.OCCUPATIONAL]: PDFieldPartNames.INTERMEDIATE,
  [VisionType.BI_FOCAL]: PDFieldPartNames.DISTANCE,
  [VisionType.BI__FOCAL]: PDFieldPartNames.DISTANCE
};

export const DEFAULT_NEAR_OFFSET = 1.5;
export const DEFAULT_INTER_OFFSET = 0.5;

export enum HorizontalPrismDirection {
  IN = 'In',
  OUT = 'Out',
  NONE = ''
}

export enum VerticalPrismDirection {
  UP = 'Up',
  DOWN = 'Down',
  NONE = ''
}

export enum EyeTestParentType {
  USER = 'User',
  ORDER = 'Order',
  ORDER_ITEM = 'OrderItem'
}

export const eyeFormLabelMap = {
  [EyePositions.LEFT]: 'Left eye (OS)',
  [EyePositions.RIGHT]: 'Right eye (OD)'
};

export const prismFormLabelMap = {
  [EyePositions.LEFT]: 'LE-Prism',
  [EyePositions.RIGHT]: 'RE-Prism'
};

export enum PrescriptionUploadStates {
  NONE = 'none',
  UPLOADED = 'uploaded',
  WILL_SEND_LATER = 'send it later',
  WE_ALREADY_HAVE_IT = 'we already have it',
  BOOKED_EYE_TEST = 'store eye test booked'
}
export const RX_UPLOAD_STATE_AND_NOTE_INFIX = ' \n \n';
export const RX_UPLOAD_STATE_PROVIDER_NAME = 'rxUploadState';
export const STANDARD_UPLOAD_STATUS = 'standard_upload_status';

export const prescriptionUploadStatusDisplay = {
  [PrescriptionUploadStates.NONE]: 'No information detected, please contact Cx.',
  [PrescriptionUploadStates.UPLOADED]: 'Rx uploaded at checkout.',
  [PrescriptionUploadStates.WILL_SEND_LATER]: 'Cx will send it later.',
  [PrescriptionUploadStates.WE_ALREADY_HAVE_IT]: 'Cx said we already have it.',
  [PrescriptionUploadStates.BOOKED_EYE_TEST]: 'Cx booked an eye test with us.'
};

export const prescriptionUploadRxStatusDisplay = {
  [PrescriptionUploadStates.NONE]: 'No information detected, please contact Cx.',
  [PrescriptionUploadStates.UPLOADED]: 'File uploaded at online checkout.',
  [PrescriptionUploadStates.WILL_SEND_LATER]: 'Cx will send it later.',
  [PrescriptionUploadStates.WE_ALREADY_HAVE_IT]: 'Cx said we already have it.',
  [PrescriptionUploadStates.BOOKED_EYE_TEST]: 'Cx booked an eye test with us.',
  [STANDARD_UPLOAD_STATUS]: 'No media added yet.'
};

export const editOrderRequestPrescriptionStatusDisplay = {
  [PrescriptionUploadStates.NONE]: 'No Rx information provided.',
  [PrescriptionUploadStates.UPLOADED]: 'Rx PARENT_ID dispensed.',
  [PrescriptionUploadStates.WILL_SEND_LATER]: 'Cx will send it later.',
  [PrescriptionUploadStates.WE_ALREADY_HAVE_IT]: 'Rx PARENT_ID dispensed.',
  [PrescriptionUploadStates.BOOKED_EYE_TEST]: 'Cx booked an eye test with us.'
};

// Common types to reduce cyclic references bewteen modules
export enum SVTypes {
  SVD = 'Dist-PD',
  SVI = 'Inter-PD',
  SVN = 'Near-PD'
}

export const MultifocalTypes = {
  Progressive: SVTypes.SVD,
  Digital: SVTypes.SVD,
  Occupation: SVTypes.SVI,
  Occupational: SVTypes.SVI,
  'Bi-focal': SVTypes.SVD,
  'Bi focal': SVTypes.SVD
};

export const PDLabelFromVisionTypeMap = {
  ...SVTypes,
  ...MultifocalTypes,
  RR: SVTypes.SVN
};

export const PDLabelFromPDTypeMap = {
  [PDTypes.DISTANCE]: SVTypes.SVD,
  [PDTypes.INTERMEDIATE]: SVTypes.SVI,
  [PDTypes.NEAR]: SVTypes.SVN
};

export const PDTypeFromVisionTypeMap = PDLabelFromVisionTypeMap; // currently UI label and internally used type values match

export const PDTypeFromInfoType = {
  [PDTypes.NEAR]: SVTypes.SVN,
  [PDTypes.INTERMEDIATE]: SVTypes.SVI,
  [PDTypes.DISTANCE]: SVTypes.SVD
};

export const PD_MIN = 22.0;
export const PD_SM = 28.0;
export const PD_LG = 34.0;
export const PD_MAX = 40.0;
export const MIN_HEIGHTS = 5.0;
export const MAX_HEIGHTS = 50.0;
export const PD_OR_HEIGHT_STEP = 0.5;
export const PD_OR_HEIGHT_DECIMAL_PLACES = 1;

export const PARENT_RX_ARCHIVED_ERROR = (rxId?: number | null) =>
  `Prescribed Rx ${rxId} was dispensed, but has since been archived. Please use an active prescribed Rx to continue.`;

export const PARENT_RX_EXPIRED_WARNING = (rxId?: number | null) =>
  `Prescribed Rx ${rxId} was dispensed, but has expired. Please only continue if approved by a Dispensing Optician.`;

export const NO_STORE_EYE_TEST_BOOKED_WARNING_HTML =
  'You have selected ‘store eye test booked’ but there is no appointment booked with this customer’s email address. <br />To be able to proceed with this option, please make sure an appointment is booked for this customer using the same email address.';

export enum CheckoutPDError {
  BELOW_SM = 'A PD below 28mm is considered very small. Please double check these measurements with a colleague before proceeding.',
  ABOVE_LG = 'A PD above 34mm is considered very large. Please double check these measurements with a colleague before proceeding.',
  BOTH = 'PDs above 34mm or below 28mm are very rare. Please double check these measurements with a colleague before proceeding.'
}
