import { AxiosPromise } from 'axios';
import request, { prepareParams } from './utils/request';
import { FulfillmentList } from './schema/fulfillment';
import { FulfilmentsStats } from './schema/fulfillmentConstants';

export const fetchAllFulfillments = (
  query?: Record<string, any>
): AxiosPromise<{
  fulfillments: FulfillmentList;
}> => {
  return request({
    url: `/fulfillments${query ? `?${prepareParams(query)}` : ''}`,
    method: 'get'
  });
};

export function fetchFulfilmentsStats(): AxiosPromise<{
  stats: FulfilmentsStats;
}> {
  return request({
    url: '/fulfillments/stats',
    method: 'get'
  });
}
