<template>
  <div class="flex grow">
    <ul class="w-58 border-r border-solid border-black bg-white px-5 pt-7 pb-md">
      <li
        v-for="menuRoute in routes"
        :key="menuRoute.name"
        class="text-body-l-reg mb-2.5 w-full text-black hover:text-dark1"
        :class="{
          'bg-beige !text-blue': route.name?.startsWith(String(menuRoute.name))
        }"
      >
        <RouterLink :to="{ name: menuRoute.name, query: menuRoute?.query }" class="block w-full px-2.5 pb-[3px] pt-0.5">
          {{ menuRoute.meta?.title }}
        </RouterLink>
      </li>
    </ul>
    <div class="relative grow overflow-y-auto" style="height: calc(100vh - 101px)">
      <div class="mx-auto max-w-[740px]">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { Location } from 'vue-router';
import { useRoute, useRouter } from 'vue-router/composables';
import { RouteItem } from '@/layout/components/Sidebar/sidebarUtils';
import { AccountStatus } from '~/api/schema/accountConstants';

// Cyclic import dependencies resolving via dynamic import
const useStore = ref<Function>();
import('@/store').then((res) => {
  useStore.value = res.default;
});

const route = useRoute();
const router = useRouter();
const store = computed(() => useStore.value && useStore.value());

const emit = defineEmits<{ (e: 'close-drawer') }>();

const ROUTE_DEFAULT_QUERY_PARAMS = {
  'accounts': { status: AccountStatus.ACTIVE, page: 1 }
};

const routes = computed(
  (): Array<RouteItem & { query?: Record<string, string | number | boolean> }> =>
    (store.value?.getters['permission_routes'] as Array<RouteItem>)
      ?.find((parent) => parent?.name?.toLowerCase() === 'settings')
      ?.children?.filter((child) => child?.meta?.menu)
      ?.map((item) => ({
        ...item,
        query: item?.name && item.name in ROUTE_DEFAULT_QUERY_PARAMS ? ROUTE_DEFAULT_QUERY_PARAMS[item.name] : undefined
      })) || []
);
watch(routes, (value) => {
  if (route.name === 'settings' && value.length && value[0].name !== route.name) {
    router.replace({ name: value[0].name, query: value[0]?.query } as Partial<Location>);
  }
});

watch(route, ({ name }) => {
  if (routes.value?.length && !routes.value?.some((item) => name?.startsWith(item.name || 'settings'))) {
    emit('close-drawer');
  }
});
</script>
