import { AxiosPromise } from 'axios';
import {
  DeviceAuthenticationResponse,
  DeviceStatusRequest,
  UnlockDeviceRequest,
  CreateDeviceTokenRequest,
  CreateDeviceTokenResponse,
  DeviceToken
} from './schema/deviceAuthentication';
import { CreateOtpResponse, OtpWarningMessages } from './schema/auth';
import request from './utils/request';
import { Administrator, Permissions } from './schema/account';
import { ApplicationType } from '~/utils/application';

export interface SignInParams {
  email: string;
  password: string;
}
export function getPermissions(): AxiosPromise<{ feature_permissions: Record<ApplicationType, Permissions> }> {
  return request({
    url: `/auth`,
    method: 'get'
  });
}
export function signIn(params: SignInParams): AxiosPromise<{
  administrator: Administrator;
  meta: {
    socket_token: string;
  };
}> {
  return request({
    url: '/auth/sign_in',
    method: 'post',
    data: params
  });
}

export const getDeviceTokenStatus = (params: { token: string }): AxiosPromise<DeviceToken> => {
  return request({
    method: 'get',
    url: `/device_tokens`,
    params
  });
};

export const getDeviceAuthenticationStatus = (
  params: DeviceStatusRequest
): AxiosPromise<DeviceAuthenticationResponse> => {
  return request({
    baseURL: `${process.env.VUE_APP_BASE_API}/admin/v1/`,
    method: 'get',
    url: `/device_token_requests`,
    params
  });
};

export const requestForDeviceAuthentication = (
  params: CreateDeviceTokenRequest
): AxiosPromise<CreateDeviceTokenResponse> => {
  return request({
    method: 'post',
    url: `/device_token_requests`,
    data: params
  });
};

export const unlockDeviceAuthentication = (params: UnlockDeviceRequest): AxiosPromise<DeviceAuthenticationResponse> => {
  return request({
    baseURL: `${process.env.VUE_APP_BASE_API}/admin/v1/`,
    method: 'put',
    url: `/device_token_requests`,
    data: params
  });
};

export function createOtp(email: string): AxiosPromise<CreateOtpResponse> {
  return request({
    url: '/auth/password',
    method: 'post',
    data: { email }
  });
}

export function validateOtp({
  otp,
  email
}: {
  otp: string;
  email: string;
}): AxiosPromise<{ success?: string; warning?: OtpWarningMessages }> {
  return request({
    url: `auth/reset_password?reset_password_token=${otp}&email=${email}`,
    method: 'get'
  });
}

export function resetPassword(params: {
  email: string;
  password: string;
  password_confirmation: string;
  reset_password_token: string;
}): AxiosPromise<{
  administrator: Administrator;
  meta: {
    socket_token: string;
  };
}> {
  return request({
    url: '/auth/password',
    method: 'put',
    data: params
  });
}

export function signOut() {
  return request({
    url: '/auth/sign_out',
    method: 'delete'
  });
}
