export const EMPTY_USER_NOTES_PLACEHOLDER = 'No note about this customer.';

export enum UserTypes {
  GUEST = 'guest',
  USER = 'user',
  ANONYMOUS = 'anonymous'
}

export enum AccountType {
  USER = 'user',
  ADMINISTRATOR = 'administrator'
}

export enum PermissionType {
  FULFILLMENT_PAGES = 'fulfillment-pages',
  ORDER_PAGE = 'order-page',
  ORDER_INDEX = 'order-index',
  CUSTOMER_PAGE = 'customer-page',
  CUSTOMER_INDEX = 'customer-index',
  EDIT_ORDER = 'edit-order',
  COLLECTION_PAGE = 'collection-page',
  PRODUCT_LISTINGS = 'product-listings',
  INVENTORY = 'inventory',
  DISCOUNTS = 'discounts',
  BLOG = 'blog',
  LOCATIONS = 'locations',
  USERS = 'users',
  USER_PERMISSIONS = 'user-permissions',
  SALES_CHANNELS = 'sales-channels',
  ANALYTICS = 'analytics',
  DEVICES = 'devices',
  CASH_TRACKING = 'cash-tracking',
  ADMINS = 'admins',
  COLLECTIONS = 'collections',
  ACCESSORIES = 'accessory',
  FRAMES = 'frames',
  LENSES = 'lenses',
  GLASSES = 'glasses',
  STORE_INVENTORY_TARGETS = 'store-inventory-targets',
  STORE_INVENTORY_REQUESTS = 'store-inventory-requests',
  STORE_INVENTORY_RETURNS = 'store-inventory-returns',
  ORDER = 'order',
  CASH_SAFE = 'cash-safe'
}

export enum PermissionValue {
  VIEW = 'view',
  EDIT = 'edit',
  EDIT_OVERRIDE = 'edit_override',
  UNRESTRICTED = 'unrestricted'
}

export enum AccountStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum PrivilegeType {
  VIEW = 'view',
  EDIT = 'edit'
}
