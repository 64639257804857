//~~legacy~~
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { Message } from 'element-ui';
import { getRequestHeaderPlatform } from '~/utils/application';
import { nullEmptyStringsFromURLsWith, nullifyEmptyStringsFromData } from '~/utils/request';
import {
  checkForPermissionsUpdate,
  clearPermissionState,
  PERMISSION_TIMEOUT_HEADER,
  getToken,
  removeToken,
  removeAdministrator
} from '~/api/utils/auth';

// create an axios instance
const service = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_API}${process.env.VUE_APP_BASE_API_ADMIN}`
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers.platform = getRequestHeaderPlatform();
    var session = getToken();

    if (session) {
      config.headers['access-token'] = session.accessToken;
      config.headers['client'] = session.client;
      config.headers['uid'] = session.uid;
    }
    if (config.data && nullEmptyStringsFromURLsWith.some((str) => config.url?.includes(str))) {
      config.data = nullifyEmptyStringsFromData(config.data);
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

const SPECIFIC_ERRORS_TO_IGNORE_NOTIFICATION = [
  'PG::UniqueViolation',
  'Login Failed. Please enter a valid email address and password.'
];

// response interceptor
service.interceptors.response.use(
  (response) => {
    if (response.headers[PERMISSION_TIMEOUT_HEADER]) {
      checkForPermissionsUpdate(parseInt(response.headers[PERMISSION_TIMEOUT_HEADER]));
    }
    return response;
  },
  (error) => {
    if (error.response.data) {
      var error_message = error.response.data.error.details;
      if (error.response.status === StatusCodes.UNAUTHORIZED) {
        removeToken();
        removeAdministrator();
        clearPermissionState();
        window.location.href = '/login';
      } else if (
        isEmptyObject(error_message) &&
        !SPECIFIC_ERRORS_TO_IGNORE_NOTIFICATION.includes(error.response.data.error.message)
      ) {
        Message({
          showClose: true,
          message: error.response.data.error.message,
          type: 'error',
          duration: 2500
        });
      }
    }
    filterError(error_message);
    return Promise.reject(error);
  }
);

function filterError(error_message) {
  for (const [key, value] of Object.entries(error_message)) {
    console.warn(key, value);

    setTimeout(function () {
      Message({
        showClose: true,
        message: key + ' : ' + value,
        type: 'error',
        duration: 4500
      });
    }, Math.random() * 1000);
  }
}

function isEmptyObject(obj) {
  return JSON.stringify(obj) === '{}';
}
export default service;
