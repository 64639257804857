import {
  DEFAULT_NOTIFICATION_HIDE_DELAY,
  DEFAULT_PRIORITIES,
  NotificationPosition,
  NotificationsOptions,
  NotificationTypes
} from '~/utils/notificationsConstants';
import { isWebApplication } from '~/utils/application';

// Cyclic import dependencies resolving via dynamic import
let useStore: typeof import('@/store').default;
import('@/store').then((res) => {
  useStore = res.default;
});

export const showNotification = (options: NotificationsOptions, forceUnmute: boolean = false) => {
  const store = useStore();
  const id = options.id || new Date().getTime();
  if (forceUnmute) {
    unmuteNotifications();
  }
  if (!store.state.notifications.muted) {
    store.dispatch('notifications/addNotification', {
      ...options,
      type: options.type || NotificationTypes.INFO,
      position:
        options.position ||
        (options.type === NotificationTypes.ERROR && !isWebApplication()
          ? NotificationPosition.TOP
          : (options.modalName && NotificationPosition.MODAL) || NotificationPosition.BOTTOM),
      // eslint-disable-next-line no-prototype-builtins
      hideDelay: options.hasOwnProperty('hideDelay') ? options.hideDelay : DEFAULT_NOTIFICATION_HIDE_DELAY,
      priority:
        options.priority ||
        // a float number that is priority.timestamp to correctly keep order of the same priority notifications as they added
        DEFAULT_PRIORITIES[options.type || NotificationTypes.INFO] + 1 - parseFloat(`0.${new Date().getTime()}`),
      // eslint-disable-next-line no-prototype-builtins
      showClose: options.hasOwnProperty('showClose') ? options.showClose : true,
      isWeb: isWebApplication(),
      id
    });
  }
  return id;
};

export const hideNotification = (id: number, noAnimation?: boolean) =>
  useStore && useStore().dispatch('notifications/hideNotification', { id, noAnimation });

export const showInfo = (options: NotificationsOptions, forceUnmute: boolean = false) =>
  showNotification({ ...options }, forceUnmute);

export const showWarning = (options: NotificationsOptions, forceUnmute: boolean = false) =>
  showNotification({ ...options, type: NotificationTypes.WARNING }, forceUnmute);

export const showError = (options: NotificationsOptions, forceUnmute: boolean = false) =>
  showNotification({ ...options, type: NotificationTypes.ERROR }, forceUnmute);

export const muteNotifications = () => useStore().commit('notifications/set_muted', true);
export const unmuteNotifications = () => useStore().commit('notifications/set_muted', false);
export const notificationsMuted = () => useStore().state.notifications.muted;
