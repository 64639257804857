//~~legacy~~
import Vue from 'vue';
import Router from 'vue-router';

import { resetModals } from '~/components/utility/modalUtility';

Vue.use(Router);

/* Layout */
import Layout from '@/layout/index.vue';
import { setupCheckVersionTimer, checkVersion } from '~/utils/application';
import { PermissionType, PermissionValue } from '~/api/schema/accountConstants';
import { FulfillmentTypes } from '~/api/schema/fulfillmentConstants';

export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    name: 'redirect',
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index.vue')
      }
    ]
  },
  { path: '/web-purchases/:id(\\d+)', redirect: '/home-deliveries/:id(\\d+)', hidden: true },
  { path: '/store-purchases/:id(\\d+)', redirect: '/store-collections/:id(\\d+)', hidden: true },
  { path: '/fullfilments/web-purchases', redirect: '/fullfilments/home-deliveries', hidden: true },
  { path: '/fullfilments/store-purchases', redirect: '/fullfilments/store-collections', hidden: true },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    hidden: true
  },
  {
    path: '/forgot-password',
    component: () => import('@/views/forgot-password/index.vue'),
    name: 'Reset Password',
    meta: {
      title: 'Reset Password'
    },
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404.vue'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/orders'
    // children: [
    //   {
    //     path: "dashboard",
    //     component: () => import("@/views/dashboard/index"),
    //     name: "Dashboard",
    //     meta: { title: "Dashboard", icon: "dashboard", affix: true },
    //   },
    // ],
  }
];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

export const asyncRoutes = [
  /** when your routing map is too long, you can split it into small modules **/
  {
    path: '/orders',
    component: Layout,
    children: [
      {
        path: '/orders',
        component: () => import('@/views/orders/index.vue'),
        name: 'orders',
        meta: { title: 'Orders', icon: 'orders' }
      },
      {
        path: '/orders/:id(\\d+)',
        component: () => import('@/views/orders/Edit/index.vue'),
        name: 'orders-id',
        meta: {
          title: 'View Order',
          noCache: true,
          activeMenu: '/orders'
        },
        hidden: true
      },
      {
        path: '/orders/:id(\\d+)/edit/hto',
        component: () => import('@/views/orders/Edit/hto.vue'),
        name: 'order-edit-hto',
        meta: {
          title: 'Edit Order',
          noCache: true,
          roles: { [PermissionType.ORDER]: [PermissionValue.EDIT] },
          activeMenu: '/orders'
        },
        hidden: true
      },
      {
        path: '/orders/:id(\\d+)/edit/purchases',
        component: () => import('@/views/orders/Edit/purchases.vue'),
        name: 'order-edit',
        meta: {
          title: 'Edit Order',
          noCache: true,
          roles: { [PermissionType.ORDER]: [PermissionValue.EDIT] },
          activeMenu: '/orders'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/fullfilments',
    component: Layout,
    redirect: '/fullfimentCenter/glasses',
    name: 'Fulfillment',
    meta: {
      title: 'Fulfilments',
      icon: 'fulfillments',
      childrenAsSubmenu: true
    },

    children: [
      {
        path: 'fulfilment-search',
        component: () => import('@/views/Fullfilments/FulfilmentSearch/index.vue'),
        name: 'Fulfilment search',
        meta: { title: 'Fulfilment search' }
      },
      {
        path: 'home-deliveries',
        component: () => import('@/views/Fullfilments/WebPurchases/index.vue'),
        name: 'Home deliveries',
        meta: { title: 'Home deliveries', fulfilmentTypes: [FulfillmentTypes.WEB_PURCHASES] }
      },
      {
        path: '/home-deliveries/:id(\\d+)',
        component: () => import('@/views/Fullfilments/WebPurchases/Edit/index.vue'),
        name: 'Home deliveries-id',
        meta: {
          title: 'Edit Home Deliveries',
          noCache: true,
          activeMenu: '/fullfilments/home-deliveries'
        },
        hidden: true
      },
      {
        path: 'store-collections',
        component: () => import('@/views/Fullfilments/StorePurchases/index.vue'),
        name: 'Store Collections',
        meta: { title: 'Store collections', fulfilmentTypes: [FulfillmentTypes.STORE_PURCHASES] }
      },
      {
        path: '/store-collections/:id(\\d+)',
        component: () => import('@/views/Fullfilments/StorePurchases/Edit/index.vue'),
        name: 'Store collections-id',
        meta: {
          title: 'Edit Store Collections',
          noCache: true,
          activeMenu: '/fullfilments/store-collections'
        },
        hidden: true
      },
      {
        path: 'accessories',
        component: () => import('@/views/Fullfilments/Accessories/index.vue'),
        name: 'Fulfillment Accessories',
        meta: { title: 'Accessories', fulfilmentTypes: [FulfillmentTypes.ACCESSORY] }
      },
      {
        path: '/accessories/:id(\\d+)',
        component: () => import('@/views/Fullfilments/Accessories/Edit/index.vue'),
        name: 'Fulfillment Accessories-id',
        meta: {
          title: 'Edit Accessories',
          noCache: true,
          activeMenu: '/fullfilments/accessories'
        },
        hidden: true
      },
      {
        path: 'home-trial-orders',
        component: () => import('@/views/Fullfilments/HomeTrialOrders/index.vue'),
        name: 'Home Trial Order',
        meta: { title: 'Home trial orders', fulfilmentTypes: [FulfillmentTypes.HOME_TRIAL] }
      },
      {
        path: 'reglazing',
        component: () => import('@/views/Fullfilments/Reglazing/index.vue'),
        name: 'Special Services',
        meta: {
          title: 'Special services',
          fulfilmentTypes: [
            FulfillmentTypes.REGLAZING,
            FulfillmentTypes.EDITING,
            FulfillmentTypes.WARRANTY_EXCHANGE_REQUEST
          ]
        }
      },
      {
        path: '/reglazing/:id(\\d+)',
        component: () => import('@/views/Fullfilments/Reglazing/Edit/index.vue'),
        name: 'Special Services-id',
        meta: {
          title: 'Edit Special Services',
          noCache: true,
          activeMenu: '/fullfilments/reglazing'
        },
        hidden: true
      },
      {
        path: '/edits/:id(\\d+)',
        component: () => import('@/views/Fullfilments/Edits/index.vue'),
        name: 'Edits',
        meta: {
          title: 'Edits',
          noCache: true,
          activeMenu: '/fullfilments/reglazing'
        },
        hidden: true
      },
      {
        path: '/warranty/:id(\\d+)',
        component: () => import('@/views/Fullfilments/Warranty/index.vue'),
        name: 'Warranty exchange request',
        meta: {
          title: 'Warranty exchange request',
          noCache: true,
          activeMenu: '/fullfilments/reglazing'
        },
        hidden: true
      },
      {
        path: '/home-trial-orders/:id(\\d+)',
        component: () => import('@/views/Fullfilments/HomeTrialOrders/Edit/index.vue'),
        name: 'Home Trial Order-id',
        meta: {
          title: 'Home Trial Order',
          noCache: true,
          activeMenu: '/fullfilments/home-trial-orders'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/customers',
    component: Layout,
    children: [
      {
        path: '/customers',
        component: () => import('@/views/customers/index.vue'),
        name: 'Customers',
        meta: { title: 'Customers', icon: 'customers' }
      },
      {
        path: ':id(\\d+)',
        component: () => import('@/views/customers/Edit/index.vue'),
        name: 'customer-edit',
        meta: {
          title: 'Edit Customer',
          noCache: true,
          activeMenu: '/customers'
        },
        hidden: true
      },
      {
        path: 'EyeTest/:id(\\d+)',
        component: () => import('@/views/customers/eyeTest/index.vue'),
        hidden: true
      },
      {
        path: '/EyeTest/add/:id(\\d+)',
        component: () => import('@/views/customers/eyeTest/Add/index.vue'),
        name: 'Add EyeTest',
        meta: {
          title: 'Add EyeTest',
          noCache: true,
          activeMenu: '/EyeTest'
        },
        hidden: true
      },
      {
        path: '/EyeTest/edit/:id(\\d+)',
        component: () => import('@/views/customers/eyeTest/Edit/index.vue'),
        name: 'Edit EyeTest',
        meta: {
          title: 'Edit EyeTest',
          noCache: true,
          activeMenu: '/EyeTest'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/lens-orders',
    component: Layout,
    redirect: '/lens-orders/all',
    name: 'Lens Orders',
    meta: {
      title: 'Lens Orders',
      icon: 'lens',
      childrenAsSubmenu: true
    },
    children: [
      {
        path: 'new',
        component: () => import('@/views/lensOrders/new.vue'),
        name: 'New Orders',
        meta: {
          title: 'New',
          noCache: true,
          activeMenu: '/lens-orders'
        }
      },
      {
        path: 'to-trace',
        component: () => import('@/views/lensOrders/to-trace.vue'),
        name: 'Orders To Trace',
        meta: {
          title: 'To Trace',
          noCache: true,
          activeMenu: '/lens-orders'
        }
      },
      {
        path: 'ongoing',
        component: () => import('@/views/lensOrders/ongoing.vue'),
        name: 'Ongoing Orders',
        meta: {
          title: 'Ongoing',
          noCache: true,
          activeMenu: '/lens-orders'
        }
      },
      {
        path: 'received',
        component: () => import('@/views/lensOrders/received.vue'),
        name: 'Received Orders',
        meta: {
          title: 'Received',
          noCache: true,
          activeMenu: '/lens-orders'
        }
      }
    ]
  },
  {
    path: '/store-inventory',
    component: Layout,
    redirect: '/store-inventory/targets',
    name: 'StoreInventory',
    meta: {
      title: 'Inventory',
      icon: 'storeInventory',
      childrenAsSubmenu: true,
      roles: [
        PermissionType.STORE_INVENTORY_REQUESTS,
        PermissionType.STORE_INVENTORY_RETURNS,
        PermissionType.STORE_INVENTORY_TARGETS
      ]
    },
    children: [
      {
        path: 'targets',
        component: () => import('@/views/storeInventory/targets.vue'),
        name: 'Targets',
        meta: {
          title: 'Targets',
          noCache: true,
          activeMenu: '/store-inventory',
          roles: [PermissionType.STORE_INVENTORY_TARGETS]
        }
      },
      {
        path: 'requests',
        component: () => import('@/views/storeInventory/requests.vue'),
        name: 'Requests Orders',
        meta: {
          title: 'Requests',
          noCache: true,
          activeMenu: '/store-inventory',
          roles: [PermissionType.STORE_INVENTORY_REQUESTS]
        }
      },
      {
        path: 'returns',
        component: () => import('@/views/storeInventory/returns.vue'),
        name: 'Returns',
        meta: {
          title: 'Returns',
          noCache: true,
          activeMenu: '/store-inventory',
          roles: [PermissionType.STORE_INVENTORY_RETURNS]
        }
      }
    ]
  },
  {
    path: '/shipments',
    component: Layout,
    redirect: '/Shipments/glasses',
    name: 'Shipments',
    meta: {
      title: 'Shipments',
      icon: 'shipments',
      childrenAsSubmenu: true
    },

    children: [
      {
        path: 'HTOTrialRadar',
        component: () => import('@/views/shipments/HTOTrialRadar/index.vue'),
        name: 'HTOTrialRadar',
        meta: { title: 'Home trial radar' }
      },
      {
        path: 'ShipmetRadarDtc',
        component: () => import('@/views/shipments/ShipmetRadarDtc/index.vue'),
        name: 'ShipmetRadarDtc',
        meta: { title: 'Website deliveries' }
      },
      {
        path: 'ShipmentRadarDtpull',
        component: () => import('@/views/shipments/ShipmentRadarDtpull/index.vue'),
        name: 'ShipmentRadarDtpull',
        meta: { title: 'Store deliveries' }
      }
    ]
  },
  {
    path: '/products',
    component: Layout,
    redirect: '/products/frames',
    name: 'Products',
    meta: {
      title: 'Products',
      icon: 'products',
      childrenAsSubmenu: true,
      roles: [
        PermissionType.COLLECTIONS,
        PermissionType.FRAMES,
        PermissionType.LENSES,
        PermissionType.GLASSES,
        PermissionType.INVENTORY
      ]
    },
    children: [
      {
        path: '/collections',
        component: () => import('@/views/collections/index.vue'),
        name: 'Collections',
        meta: { title: 'Collections', roles: [PermissionType.COLLECTIONS] }
      },
      {
        path: '/collections/:id(\\d+)',
        component: () => import('@/views/collections/Edit/index.vue'),
        name: 'Collections-id',
        meta: {
          title: 'Edit Collections',
          noCache: true,
          activeMenu: '/collections',
          roles: [PermissionType.COLLECTIONS]
        },
        hidden: true
      },
      {
        path: '/collections/create',
        component: () => import('@/views/collections/Create/index.vue'),
        name: 'Collections-add',
        meta: {
          title: 'Create Collections',
          noCache: true,
          activeMenu: '/collections',
          roles: { [PermissionType.COLLECTIONS]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: 'tailorMadeGlasses',
        component: () => import('@/views/products/tailorMadeGlasses/index.vue'),
        name: 'Tailor Made Glasses',
        meta: { title: 'Tailor made glasses' },
        hidden: true
      },
      {
        path: 'tailorMadeGlasses/create',
        component: () => import('@/views/products/tailorMadeGlasses/Create/index.vue'),
        name: 'Tailor Made Glasses-add',
        meta: {
          title: 'Create Tailor',
          noCache: true,
          activeMenu: '/products/tailorMadeGlasses'
        },
        hidden: true
      },
      {
        path: 'tailorMadeGlasses/:id(\\d+)',
        component: () => import('@/views/products/tailorMadeGlasses/Edit/index.vue'),
        name: 'Tailor Made Glasses-id',
        meta: {
          title: 'Edit Tailor',
          noCache: true,
          activeMenu: '/products/tailorMadeGlasses'
        },
        hidden: true
      },
      {
        path: 'frames',
        component: () => import('@/views/products/frames/index.vue'),
        name: 'Frames',
        meta: { title: 'Frames', roles: [PermissionType.FRAMES] }
      },
      {
        path: 'frames/:id(\\d+)',
        component: () => import('@/views/products/frames/Edit/index.vue'),
        name: 'EditFrame',
        meta: {
          title: 'Edit Frame',
          noCache: true,
          activeMenu: '/products/frames',
          roles: [PermissionType.FRAMES]
        },
        hidden: true
      },
      {
        path: 'frames/frame-variant/:id(\\d+)',
        component: () => import('@/views/products/frames/Edit/EditVariant/index.vue'),
        name: 'EditVariant',
        meta: {
          title: 'Edit Variant',
          noCache: true,
          activeMenu: '/products/frames',
          roles: [PermissionType.FRAMES]
        },
        hidden: true
      },
      {
        path: 'frames/create',
        component: () => import('@/views/products/frames/Create/index.vue'),
        name: 'CreateFrame',
        meta: {
          title: 'Create Frame',
          noCache: true,
          activeMenu: '/products/frames',
          roles: { [PermissionType.FRAMES]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: 'frames/createvariant',
        component: () => import('@/views/products/frames/Create/CreateVariant/index.vue'),
        name: 'CreateVariant',
        meta: {
          title: 'Create Frame Variant',
          noCache: true,
          activeMenu: '/products/frames',
          roles: { [PermissionType.FRAMES]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: 'accessories',
        component: () => import('@/views/products/accessories/index.vue'),
        name: 'accessories',
        meta: {
          title: 'Accessories',
          roles: [PermissionType.ACCESSORIES]
        }
      },
      {
        path: 'accessories/edit/:id(\\d+)',
        component: () => import('@/views/products/accessories/_id.vue'),
        name: 'accessories-edit',
        meta: {
          title: 'Edit accessory',
          noCache: true,
          activeMenu: '/products/accessories',
          roles: { [PermissionType.ACCESSORIES]: [PermissionValue.EDIT, PermissionValue.VIEW] }
        },
        hidden: true
      },
      {
        path: 'accessories/add',
        component: () => import('@/views/products/accessories/_id.vue'),
        name: 'accessories-add',
        meta: {
          title: 'Create accessory',
          noCache: true,
          activeMenu: '/products/accessories',
          roles: { [PermissionType.ACCESSORIES]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: 'lenses',
        component: () => import('@/views/products/lenses/index.vue'),
        name: 'Lenses',
        meta: { title: 'Lenses', roles: [PermissionType.LENSES] }
      },
      {
        path: 'lenses/lens-variant/:id(\\d+)',
        component: () => import('@/views/products/lenses/Edit/EditVariant/index.vue'),
        name: 'EditLensVariant',
        meta: {
          title: 'Edit Lens Variant',
          noCache: true,
          activeMenu: '/products/lenses',
          roles: [PermissionType.LENSES]
        },
        hidden: true
      },
      {
        path: 'lenses/:id(\\d+)',
        component: () => import('@/views/products/lenses/Edit/index.vue'),
        name: 'EditLens',
        meta: {
          title: 'Edit Lens',
          noCache: true,
          activeMenu: '/products/lenses',
          roles: [PermissionType.LENSES]
        },
        hidden: true
      },
      {
        path: 'lenses/edit',
        component: () => import('@/views/products/lenses/Edit/index.vue'),
        name: 'Edit lens',
        meta: {
          title: 'Edit Lens',
          noCache: true,
          activeMenu: '/products/lenses',
          roles: [PermissionType.LENSES]
        },
        hidden: true
      },
      {
        path: 'lenses/createvariant',
        component: () => import('@/views/products/lenses/Create/CreateVariant/index.vue'),
        name: 'CreateLensVariant',
        meta: {
          title: 'Create Lens Variant',
          noCache: true,
          activeMenu: '/products/lenses',
          roles: { [PermissionType.LENSES]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: 'lenses/create',
        component: () => import('@/views/products/lenses/Create/index.vue'),
        name: 'Create Lense',
        meta: {
          title: 'Create Lens',
          noCache: true,
          activeMenu: '/products/lenses',
          roles: { [PermissionType.LENSES]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: 'glasses',
        component: () => import('@/views/products/glasses/index.vue'),
        name: 'Glasses',
        meta: { title: 'Glasses', roles: [PermissionType.GLASSES] }
      },
      {
        path: 'glasses/create',
        component: () => import('@/views/products/glasses/Create/index.vue'),
        name: 'CreateGlasses',
        meta: {
          title: 'Create Glasses',
          noCache: true,
          activeMenu: '/products/glasses',
          roles: { [PermissionType.GLASSES]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: 'glasses/createvariant',
        component: () => import('@/views/products/glasses/Create/CreateVariant/index.vue'),
        name: 'createglassvariant',
        meta: {
          title: 'Create Glass Variant',
          noCache: true,
          activeMenu: '/products/glasses',
          roles: { [PermissionType.GLASSES]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: 'glasses/glass-variant/:id(\\d+)',
        component: () => import('@/views/products/glasses/Edit/EditVariant/index.vue'),
        name: 'editglassvariant',
        meta: {
          title: 'Edit Glass Variant',
          noCache: true,
          activeMenu: '/products/glasses',
          roles: [PermissionType.GLASSES]
        },
        hidden: true
      },
      {
        path: 'glasses/glass-variant/hto/:id(\\d+)',
        component: () => import('@/views/products/glasses/Edit/EditVariant/HTO/index.vue'),
        name: 'editglassvarianthto',
        meta: {
          title: 'Glass Variant HTO',
          noCache: true,
          activeMenu: '/products/glasses',
          roles: [PermissionType.GLASSES]
        },
        hidden: true
      },
      {
        path: 'glasses/:id(\\d+)',
        component: () => import('@/views/products/glasses/Edit/index.vue'),
        name: 'Editglasses',
        meta: {
          title: 'Edit Glasses',
          noCache: true,
          activeMenu: '/products/glasses',
          roles: [PermissionType.GLASSES]
        },
        hidden: true
      },

      {
        path: '/inventory',
        component: () => import('@/views/inventory/index.vue'),
        name: 'Inventory',
        meta: { title: 'Inventory', roles: [PermissionType.INVENTORY] }
      }
    ]
  },
  {
    path: '/discounts',
    component: Layout,
    meta: { title: 'Discounts', icon: 'discounts', roles: [PermissionType.DISCOUNTS] },
    children: [
      {
        path: '/discounts',
        component: () => import('@/views/discounts/index.vue'),
        name: 'Discounts',
        meta: { title: 'Discounts', roles: [PermissionType.DISCOUNTS] }
      },
      {
        path: 'create',
        component: () => import('@/views/discounts/Create/index.vue'),
        name: 'CreateDiscount',
        meta: {
          title: 'Create Discount',
          noCache: true,
          activeMenu: '/discounts',
          roles: { [PermissionType.DISCOUNTS]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: ':id(\\d+)',
        component: () => import('@/views/discounts/Edit/index.vue'),
        name: 'EditDiscount',
        meta: {
          title: 'Edit Discount',
          noCache: true,
          activeMenu: '/discounts',
          roles: [PermissionType.DISCOUNTS]
        },
        hidden: true
      }
    ]
  },
  {
    path: '/blog',
    component: Layout,
    meta: {
      title: 'Blog',
      icon: 'blogs',
      roles: [PermissionType.BLOG]
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/blog/index.vue'),
        name: 'blog',
        meta: { title: 'Blog', icon: 'blogs', roles: [PermissionType.BLOG] },
        activeMenu: '/blog'
      },
      {
        path: 'Add',
        component: () => import('@/views/blog/Add/index.vue'),
        name: 'Addblog',
        meta: {
          title: 'Add Blog',
          noCache: true,
          activeMenu: '/blog',
          roles: { [PermissionType.BLOG]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: ':id(\\d+)',
        component: () => import('@/views/blog/Edit/index.vue'),
        name: 'Editblog',
        meta: {
          title: 'Edit Blog',
          noCache: true,
          activeMenu: '/blog',
          roles: [PermissionType.BLOG]
        },
        hidden: true
      }
    ]
  },
  {
    path: '/pd-tool',
    component: Layout,
    activeMenu: '/pd-tool',
    children: [
      {
        path: '/pd-tool',
        component: () => import('@/views/pd-tool.vue'),
        name: 'pd-tool',
        meta: { title: 'PD tool', icon: 'pdtool' },
        activeMenu: '/pd-tool'
      }
    ]
  },
  {
    path: '/settings',
    component: Layout,
    name: 'settings',
    meta: {
      title: 'Settings',
      icon: 'settings',
      isBottom: true,
      roles: [PermissionType.LOCATIONS, PermissionType.SALES_CHANNELS, PermissionType.ADMINS]
    },
    activeMenu: '/settings',
    children: [
      {
        path: 'accounts',
        component: () => import('@/views/settings/accounts/index.vue'),
        name: 'accounts',
        meta: {
          title: 'Admin users',
          noCache: true,
          activeMenu: '/settings',
          roles: [PermissionType.ADMINS],
          menu: true
        },
        hidden: true
      },
      {
        path: 'accounts/:id(\\d+|create)',
        component: () => import('@/views/settings/accounts/_id.vue'),
        name: 'accounts-id',
        meta: {
          title: 'Edit Account',
          noCache: true,
          activeMenu: '/settings',
          roles: [PermissionType.ADMINS]
        },
        hidden: true
      },
      {
        path: 'saleschannel',
        component: () => import('@/views/settings/saleschannel/index.vue'),
        name: 'saleschannel',
        meta: {
          title: 'Sales channels',
          noCache: true,
          activeMenu: '/settings',
          roles: [PermissionType.SALES_CHANNELS],
          menu: true
        },

        hidden: true
      },
      {
        path: 'saleschannel/add',
        component: () => import('@/views/settings/saleschannel/Add/index.vue'),
        name: 'saleschannel-add',
        meta: {
          title: 'Add Sales Channel',
          noCache: true,
          roles: [PermissionType.SALES_CHANNELS],
          activeMenu: '/settings'
        },
        hidden: true
      },
      {
        path: 'saleschannel/:id(\\d+)',
        component: () => import('@/views/settings/saleschannel/Edit/index.vue'),
        name: 'saleschannel-edit',
        meta: {
          title: 'Edit Sales Channel',
          noCache: true,
          roles: [PermissionType.SALES_CHANNELS],
          activeMenu: '/settings'
        },
        hidden: true
      },
      {
        path: 'location',
        component: () => import('@/views/settings/location/index.vue'),
        name: 'location',
        meta: {
          title: 'Fulfilment centres',
          noCache: true,
          activeMenu: '/settings',
          roles: [PermissionType.LOCATIONS],
          menu: true
        },
        hidden: true
      },
      {
        path: 'location/add',
        component: () => import('@/views/settings/location/Add/index.vue'),
        name: 'location-add',
        meta: {
          title: 'Add Location',
          noCache: true,
          roles: [PermissionType.LOCATIONS],
          activeMenu: '/settings'
        },
        hidden: true
      },
      {
        path: 'location/:id(\\d+)',
        component: () => import('@/views/settings/location/Edit/index.vue'),
        name: 'location-edit',
        meta: {
          title: 'Edit Location',
          noCache: true,
          roles: [PermissionType.LOCATIONS],
          activeMenu: '/settings'
        },
        hidden: true
      }
    ]
  },
  // 404 page must be placed at the end !!!
  { path: '/:pathMatch(.*)*', redirect: '/404', hidden: true }
];

const createRouter = () =>
  new Router({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  });

const router = createRouter();

const pathRegexps = constantRoutes
  .map((route) => route.path)
  .concat(asyncRoutes.map((route) => route.path))
  .filter((route) => route?.length > 1)
  .map((path) => new RegExp(`^#${path}?.+$`));

router.beforeEach((to, from, next) => {
  // reset modals if any open
  if (to.fullPath !== from.fullPath) {
    resetModals();
  }

  //redirect fix for old links with /#/
  if (to.hash.includes('#/') && pathRegexps.some((regexp) => regexp.test(to.hash))) {
    next(to.hash.replace('#', ''));
  } else {
    next();
  }
});

router.afterEach((_to, _from) => {
  // check application version
  checkVersion();
  // setup version check timer
  setupCheckVersionTimer();
});

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
