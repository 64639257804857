// TODO: review usage of functions here and add unit tests
import { ref } from 'vue';
import Cookies from 'js-cookie';
import { getRequestHeaderPlatform } from '~/utils/application';
import { SalesChannel } from '~/api/schema/payment';
import { PermissionType, PermissionValue } from '~/api/schema/accountConstants';
import { CreateDeviceTokenResponse } from '~/api/schema/deviceAuthentication';
import type { Administrator, Permissions } from '~/api/schema/account';

export const PERMISSION_TIMEOUT_HEADER = 'x-admin-rights-update-timestamp';

const UID = 'uid';
const CLIENT = 'client';
const ACCESS_TOKEN = 'access-token';
const ADMINISTRATOR = 'administrator';
const SALES_CHANNEL = 'sales-channel';
const UUID = 'uuid';
const DEVICE_TOKEN = 'device-token';
const DEVICE_TOKEN_RESPONSE = 'device-token-response';
const PIN_CODE = 'pincode';

export const getToken = () => {
  if (Cookies.get(ACCESS_TOKEN) && Cookies.get(UID) && Cookies.get(CLIENT)) {
    return {
      accessToken: Cookies.get(ACCESS_TOKEN),
      uid: Cookies.get(UID),
      client: Cookies.get(CLIENT)
    };
  }
  return null;
};

export const getDeviceToken = (): string | undefined => {
  const deviceToken = Cookies.get(DEVICE_TOKEN);
  return deviceToken || undefined;
};

export const removeDeviceToken = () => {
  return Cookies.remove(DEVICE_TOKEN);
};

export const getDeviceTokenResponse = (): CreateDeviceTokenResponse | undefined | null => {
  const deviceTokenResponseJSON = Cookies.get(DEVICE_TOKEN_RESPONSE);
  if (deviceTokenResponseJSON) {
    return JSON.parse(deviceTokenResponseJSON) as CreateDeviceTokenResponse;
  }
  return null;
};

export const setToken = (uid, client, accessToken) => {
  Cookies.set(UID, uid);
  Cookies.set(CLIENT, client);
  Cookies.set(ACCESS_TOKEN, accessToken);
};

export const setDeviceToken = (deviceToken: string) => {
  Cookies.set(DEVICE_TOKEN, deviceToken, { expires: 365 * 50 });
};

export const setDeviceTokenResponse = (deviceTokenResponse: CreateDeviceTokenResponse | null) => {
  if (deviceTokenResponse) {
    Cookies.set(DEVICE_TOKEN_RESPONSE, JSON.stringify(deviceTokenResponse), { expires: 2 });
  } else {
    Cookies.remove(DEVICE_TOKEN_RESPONSE);
  }
};

export const removeToken = () => {
  Cookies.remove(UID);
  Cookies.remove(CLIENT);
  Cookies.remove(ACCESS_TOKEN);
  localStorage.removeItem('vuex');
};

export const removePinCode = () => {
  Cookies.remove(PIN_CODE);
};

export const getAdministrator = () => {
  const adminJSON = Cookies.get(ADMINISTRATOR) as string | undefined;
  if (adminJSON) {
    return JSON.parse(adminJSON) as Administrator;
  }
  return null;
};

export const setAdministrator = (administrator?: Administrator) => {
  permissionsState.value.permissions = administrator?.permissions as Permissions | undefined;
  Cookies.set(ADMINISTRATOR, JSON.stringify(administrator));
};

export const removeAdministrator = () => {
  return Cookies.remove(ADMINISTRATOR);
};

const permissionsState = ref<{
  permissions?: Permissions;
  lastUpdateTimestamp: number;
  processing: boolean;
  callbacks: Record<string, Function>;
}>({
  permissions: undefined,
  lastUpdateTimestamp: -1,
  processing: false,
  callbacks: {}
});

export function checkForPermissionsUpdate(timestamp: number) {
  if (
    !permissionsState.value.processing &&
    (timestamp > permissionsState.value.lastUpdateTimestamp || !permissionsState.value.permissions)
  ) {
    import('~/api/auth').then((res) => {
      permissionsState.value.processing = true;
      res
        .getPermissions()
        .then(({ data }) => {
          const permissions = data.feature_permissions?.[getRequestHeaderPlatform()];
          const admin = getAdministrator();
          if (admin) {
            setAdministrator({ ...admin, permissions });
          }
          permissionsState.value.permissions = permissions;
          permissionsState.value.lastUpdateTimestamp = timestamp;
          Object.values(permissionsState.value.callbacks).forEach((fn) => fn());
        })
        .catch((error) => console.log(error))
        .finally(() => (permissionsState.value.processing = false));
    });
  }
}

export function addPermissionChangeCallback(fn: Function) {
  if (!Object.keys(permissionsState.value.callbacks).includes(fn.name)) {
    permissionsState.value.callbacks = { ...permissionsState.value.callbacks, [fn.name]: fn };
  }
}

export function clearPermissionState() {
  permissionsState.value.lastUpdateTimestamp = -1;
  permissionsState.value.permissions = undefined;
}

export function getPermissions(): Permissions | undefined {
  return permissionsState.value.permissions || (getAdministrator()?.permissions as Permissions | undefined);
}

export const hasPermission = (permission: PermissionType, value: PermissionValue): boolean => {
  const permissions = permissionsState.value.permissions;
  return permissions && permissions[permission] ? permissions[permission].includes(value) : false;
};

export const getSalesChannel = () => {
  const salesChannelJSON = Cookies.get(SALES_CHANNEL) as string | undefined;
  if (salesChannelJSON) {
    return JSON.parse(salesChannelJSON) as SalesChannel;
  }
  return null;
};

export const setSalesChannel = (salesChannel) => {
  Cookies.set(SALES_CHANNEL, JSON.stringify(salesChannel), { expires: 365 * 50 });
};

export const removeSalesChannel = () => {
  return Cookies.remove(SALES_CHANNEL);
};

export const setUUID = (uuid) => {
  localStorage.setItem(UUID, uuid);
};

export const getUUID = () => {
  if (localStorage.getItem(UUID)) {
    return localStorage.getItem(UUID);
  }
  return null;
};
