import { detect } from 'detect-browser';

export enum Routes {
  LOGIN = 'login',
  FORGOT_PASSWORD = 'forgot-password',
  INDEX = 'index',
  CHECKOUT = 'checkout'
}

export enum OutdatedRoutes {
  MASTER_LOGIN = 'master-login'
}

const guestRoutes = [Routes.LOGIN, Routes.FORGOT_PASSWORD];
const ADMIN = 'ADMIN';

export function isAdminApplication(): boolean {
  return process.env.VUE_APP_TYPE === ADMIN;
}

export const generateFingerPrintToken = async () => {
  let fingerprint: string | undefined;
  const timestamp = new Date().getTime();
  const rand = (Math.random() * 100000000).toFixed();
  const browser = detect();
  if (crypto.subtle) {
    const hash = await crypto.subtle.digest(
      'SHA-1',
      new TextEncoder().encode(JSON.stringify([timestamp, browser, rand]))
    );
    fingerprint = Array.from(new Uint8Array(hash))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
  } else {
    fingerprint = `${Math.floor(Math.random() * 10000000)}`;
  }
  return fingerprint;
};

export const isGuestRoute = (path?: string | null) => guestRoutes.some((route) => path?.includes(route));

export const isOutdatedRoute = (path?: string | null) =>
  Object.values(OutdatedRoutes).some((route) => path?.includes(route));
