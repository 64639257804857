import numberFormatter from 'format-number';
import { PD_OR_HEIGHT_DECIMAL_PLACES, PD_OR_HEIGHT_STEP } from '~/api/schema/eyeTestConstants';

export const truncateString = (str: string, len: number) => {
  return str.length > len ? `${str.slice(0, len)}...` : str;
};

export const isPlural = (num: number) => (num > 1.0 || num < 1.0) && (num > -1.0 || num < -1.0);

export const sIfPlural = (num: number) => (isPlural(num) ? 's' : '');

// toFixed does not round up, so we need to do it manually, and also assure number processor precision
// would not affect it. For example, Math.round(102.5) still returns 102 instead of 103, so we use workaround.
// TODO: review the usage of "toFixed", "Math.round" etc. and make sure correct rounding is performed.
export const roundUp = (num: number, decimals: number = 0) => {
  const mult = Math.pow(10, decimals);
  return (Math.round(num * mult + 0.00000001) / mult).toFixed(decimals);
};

// TODO: review all usage of "parseFloat" and possibly change to use this function if thousands
//  separator may be included in the number.
export const parseFloatWithThousands = (num: string) => (num ? parseFloat(String(num).replace(',', '')) : undefined);

export const formatNumber = (num: number, decimals: number, addSign?: boolean): string => {
  return numberFormatter({
    prefix: addSign && num > 0 ? '+' : '',
    padRight: decimals
  })(Number(isFinite(num) ? roundUp(num, decimals) : NaN));
};
export const formatNumberToFixedOrOriginal = (value: string | number, decimals: number = 2) => {
  const currentDecimalCount = (value.toString().split('.')[1] || '').length;
  if (currentDecimalCount <= decimals) {
    return Number(value).toFixed(decimals);
  } else {
    return Number(value);
  }
};

export const numberToSignedString = (num: number, decimals: number): string => formatNumber(num, decimals, true);

export const roundWithPrecision = (value: number, precision: number) => Math.round(value / precision) * precision;

export const roundHalf = (value: number) => roundWithPrecision(value, 0.5);

export const formatPDValue = (value: number) =>
  roundUp(roundWithPrecision(value, PD_OR_HEIGHT_STEP), PD_OR_HEIGHT_DECIMAL_PLACES);

export const formatPDValuesPair = (record?: Record<string, any> | null, fieldSuffix: string = ''): string | null => {
  const rightPD = record?.[`right${fieldSuffix}`];
  const leftPD = record?.[`left${fieldSuffix}`];
  return (rightPD && leftPD && `${formatPDValue(rightPD)}/${formatPDValue(leftPD)}`) || null;
};

export const HCL = 'HCL';

export const formatPDValueForEditing = (
  value: string | number | undefined | null | File,
  isBoth: boolean = false,
  isHeights?: boolean
) => {
  if (String(value).includes('/')) {
    return String(value)
      .split('/')
      .map((val) => formatPDValueForEditing(val, isBoth, isHeights))
      .join('/');
  } else if (isHeights && isBoth && String(value).toUpperCase() === HCL) {
    return HCL;
  } else if (Number(value)) {
    return formatPDValue(Number(value));
  } else {
    return isBoth ? value : '';
  }
};

export function nullifyEmptyString(value: any) {
  return value === '' ? null : value;
}

// re-group dash-separated group-items list into the grouped strings of groups with children in parentheses.
// For example, "Option - Child, Option - Child 2, Option 2, Option 3 - Child 3"
// will be represented as "Option (Child, Child 2), Option 2, Option 3 (Child 3)"
export const reGroupDashSeparated = (items: Array<string>): Array<string> =>
  Object.entries(
    items.reduce((result, detail) => {
      const parts = detail.split(' - ');
      return {
        ...result,
        [parts[0]]: [...(result[parts[0]] || []), parts.length > 1 ? parts[1] : undefined]
      };
    }, {} as Record<string, Array<string | undefined>>)
  ).map(([group, groupValues]) => {
    const values = groupValues.filter((val) => val).map((val) => val!.toLowerCase());
    return `${group}${values.length ? ` (${values.join(', ')})` : ''}`;
  });

export const isExternal = (path: string) => /^(https?:|mailto:|tel:)/.test(path);

export function generateConfigurationName(str: string) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+|_+/g, '');
}
