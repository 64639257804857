<template>
  <div
    v-if="active"
    class="measure-container relative flex max-h-full"
    :style="cssHeight ? { height: cssHeight, maxHeight: cssHeight } : null"
  >
    <div ref="scrollerRef" class="scrollbar !my-0 max-h-full min-h-5 overflow-y-auto !pr-0" :class="scrollerClass">
      <slot />
    </div>
  </div>
  <div v-else :style="cssHeight ? { height: cssHeight, maxHeight: cssHeight } : null">
    <slot />
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';

withDefaults(
  defineProps<{
    active?: boolean;
    cssHeight?: string;
    scrollerClass?: string | Object;
  }>(),
  {
    active: true,
    cssHeight: undefined,
    scrollerClass: undefined
  }
);

const scrollerRef = ref();

defineExpose({
  resetScrolling: () => scrollerRef.value && (scrollerRef.value.scrollTop = 0)
});
</script>
<style scoped>
.border-bottom-padding {
  &::-webkit-scrollbar-thumb {
    border-bottom: 30px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
}
</style>
